html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

html {
  background: rgba(238, 238, 238, 1);
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 1);
}

img {
  max-width: 100%;
}

.table {
  margin-bottom: 40px !important;
}

.ui.header {
  padding: 0.25em 0 0 0.25em;
}

.ui.menu {
  margin-bottom: 0 !important;
  border-radius: 0 !important;
}

.publiccontent {
  flex: 1;
  padding: 1.25em 2em 1em;
  color: #fff;
}

.homepage-container,
.privacy-container {
  background: url(images/home-screen-bg.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.privacy-container {
  padding: 3em 0;
}

.homepage-top h2 {
  color: white;
}

.logo-container {
  margin-top: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-container img {
  max-width: 80%;
}

.content-container {
  padding: 1em;
}

.appstore-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1024px) and (max-width: 1100px) {
  .homepage-container {
    min-height: 100vh;
  }
}

@media screen and (min-width: 800px) {
  .logo-container img {
    max-width: 84%;
  }

  .content-container {
    width: 80%;
    margin: auto;
  }
}

@media screen and (min-width: 975px) {
  .appstore-buttons-container {
    display: block;
  }
}

@media screen and (min-width: 1000px) {
  .logo-container img {
    max-width: 90%;
  }
}

.homepage-mail-link {
  color: white;
  text-align: center;
  margin-top: 2.25em;
  font-size: 1em;
}

.homepage-mail-link a {
  color: white;
}

@media screen and (min-width: 1000px) {
  .homepage-mail-link {
    font-size: 1.25em;
  }
}

.text-shadow {
  text-shadow: 1px 1px black;
}

.homepage-subhead {
  color: #fff;
  font-size: 1.5em;
  padding-left: 0.35em;
}

@media screen and (min-width: 768px) {
  .homepage-subhead {
    font-size: 1.9em;
    margin-right: 1em;
  }
}

@media screen and (min-width: 1000px) {
  .homepage-subhead {
    font-size: 2.5em;
  }
}

.appstore-button {
  margin: 0.35em 0 0.5em;
}

.bullet-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 1.5em;
}

.publicfooter {
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
  color: #fff;
}

.publicfooter a {
  color: #fff;
  margin-bottom: 1em;
}

.admincontent {
  padding: 1.25em 2em 1em;
  background: rgba(238, 238, 238, 1);
}

.blocklabel {
  display: block;
  padding-top: 0.5em;
}

.form-addcattype {
  margin-top: 0.5em;
}

/* right align pagination in table footer */
.tablePaginationColumn > .pagination,
.tablePerPageColumn > form {
  float: right;
  clear: right;
}

.tablePerPageColumn .ui.form .inline.fields {
  margin-bottom: 0;
}

.tableItemNumbers {
  padding: 0.65em 0.5em 0.5em;
}

.tablename {
  font-weight: 700;
}

.formButtonGroup {
  margin: 1em 0 1.5em !important;
}

.categoryform {
  margin-top: 1em;
}

.categoryFormSelectedImage {
  width: 150px;
  height: 150px;
  margin-bottom: 0.75em;
}

.answerinput {
  margin: 0.5em 0 1em !important;
}

.pushactionbuttonscontainer {
  display: flex;
  justify-content: flex-end;
}

.questionstatusbutton {
  margin-top: 0.5em !important;
}

.publishedstatuscell {
  text-transform: capitalize;
}

.mobileColumn {
  padding-top: 0.5em;
}

.answer {
  padding-bottom: 1.5em;
  margin-bottom: 1em;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.answer:last-of-type {
  border-bottom: 0px;
  padding-bottom: 0;
  margin-bottom: 0;
}

/*override style that messes up question answers distribution*/
.ui.card > .content {
  flex-grow: 0 !important;
}

.stretchCard {
  display: flex;
  height: 100%;
}

.stretchCard .content:nth-of-type(1) {
  flex-grow: 0;
}

.cardcentercontent {
  margin: 0 auto !important;
}

@media screen and (min-width: 768px) {
  html,
  body,
  #root,
  .adminSidebar {
    min-height: 100vh;
    height: 100%;
  }
  .admincontent {
    padding: 1.25em 2em 1em;
    height: 100%;
  }
  .questionCard {
    height: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .mobileColumn {
    padding-top: 0;
  }
}

/*adjust input font size */

.form h3 {
  font-size: 1.5em;
}

.card,
.field label {
  font-size: 1.1em !important;
}

#question,
#answer {
  font-size: 1.1em !important;
}
